import {
  Col, Navbar, Container,
} from 'react-bootstrap';
import { useGetExchangeRates } from '../../utils/hooks';
import logo from '../../assets/logo-8.webp';
import { useContext } from 'react';
import AppContext from '../../appContext';
import './Header.css';

function Header() {
  const { userWallet } = useContext(AppContext);

  const exchangeRate = useGetExchangeRates();
  return (
    <Container
      className="p-2"
    >
      <header
        className="row align-items-center"
        role="banner"
      >
        <Navbar.Brand
          href="/"
          className="col d-flex align-items-center"
        >
          <img alt="tron-logo" src={logo} width={182} height={57} />
        </Navbar.Brand>
        <Col
          className="exchange-rates-container"
        >
          <span>
            {`TRX: ${parseFloat(exchangeRate?.lastPrice).toFixed(4)} USDT `}
            <span style={{ color: exchangeRate?.priceChangePercent > 0 ? 'green' : 'red' }}>
              (
              {` ${exchangeRate?.priceChangePercent > 0 ? '+' : ''}${parseFloat(exchangeRate?.priceChangePercent).toFixed(2)}% `}
              )
            </span>
          </span>
        </Col>

        <Col className="wallet-connect">
          <h5>
            <svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.7895 18.4689V15.5556C34.7895 14.15 33.7 13 32.3684 13H15.4211C14.0774 13 13 14.15 13 15.5556V33.4444C13 34.85 14.0774 36 15.4211 36H32.3684C33.7 36 34.7895 34.85 34.7895 33.4444V30.5311C35.5037 30.0839 36 29.2789 36 28.3333V20.6667C36 19.7211 35.5037 18.9161 34.7895 18.4689ZM33.5789 20.6667V28.3333H25.1053V20.6667H33.5789ZM15.4211 33.4444V15.5556H32.3684V18.1111H25.1053C23.7737 18.1111 22.6842 19.2611 22.6842 20.6667V28.3333C22.6842 29.7389 23.7737 30.8889 25.1053 30.8889H32.3684V33.4444H15.4211Z" fill="rgb(194, 54, 49)" />
            </svg>
            {userWallet || 'Connect Wallet'}
          </h5>
        </Col>

      </header>
    </Container>
  );
}

export default Header;

const contractAddress = 'TKfXvcL7fBsSfVjfVAFrWBdWyx7SvKD619';
const contractAddressHex = '416a59911ffdcb78f53080dd3f5609cc9c0bae09ea';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { stateMutability: 'Payable', type: 'Fallback' }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'addAddressToBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'blacklisted', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }], name: 'bonusLifetime', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'timestamp', type: 'uint32' }], name: 'deleteBonusesByAddress', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }, { type: 'uint64' }, { type: 'uint32' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'period', type: 'uint32' }, { name: 'value', type: 'uint64' }], name: 'editBonus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], inputs: [{ name: 'firstPeriod', type: 'uint32' }, { name: 'wallet', type: 'address' }], name: 'editFirstPeriod', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], inputs: [{ name: 'lastPeriod', type: 'uint32' }, { name: 'wallet', type: 'address' }], name: 'editLastPeriod', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], inputs: [{ name: 'lastWithdrawPeriod', type: 'uint32' }, { name: 'wallet', type: 'address' }], name: 'editLastWithdrawPeriod', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint64' }, { type: 'uint32' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getClosestWithdrawValue', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint64[]' }, { type: 'uint32[]' }, { type: 'uint32' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getUsersBonuses', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getWithdrawValue', stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'gml', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint64[]' }, { type: 'uint32[]' }, { type: 'uint32' }, { type: 'address' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'shift', type: 'uint32' }, { name: 'numbers', type: 'uint32' }], name: 'migrate', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32[]' }, { type: 'uint32[]' }, { type: 'uint64[]' }, { type: 'uint32[]' }, { type: 'address' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'shift', type: 'uint32' }, { name: 'inNumbers', type: 'uint32' }], name: 'migrateOld', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ name: 'lastWithdrawPeriod', type: 'uint32' }, { name: 'lastPeriod', type: 'uint32' }, { name: 'firstPeriod', type: 'uint32' }], inputs: [{ type: 'address' }], name: 'referralsBonus', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'removeAddressFromBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }], inputs: [{ name: 'value', type: 'uint8' }], name: 'setBonusLifetime', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'setFromContractAddress', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'startDates', type: 'uint32[]' }, { name: 'endDates', type: 'uint32[]' }, { name: 'referrals', type: 'address[]' }, { name: 'amounts', type: 'uint64[]' }], name: 'setReferralBonus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'setToContractAddress', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'inputTronTradeAddress', type: 'address' }], name: 'setTronTradeAddress', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], inputs: [{ name: 'value', type: 'uint32' }], name: 'setWithdrawPeriod', stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }], name: 'tronTradeAddress', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }, { type: 'uint256' }, { type: 'uint256' }], name: 'withdraw', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'withdrawPeriod', stateMutability: 'view', type: 'function',
  }, { stateMutability: 'Payable', type: 'Receive' }],
};

const BonusNewContract = { contractAddress, contractAddressHex, contractAbi };
export default BonusNewContract;

const contractAddress = 'THxUnXMH3etnd3YzAgecidafjte5ocaz7q';
const contractAddressHex = '41579dc41a7336b08f5836cea654ace19adae9f4b0';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: 'wallet', type: 'address' }, { name: 'startDate', type: 'uint32' }, { name: 'endDate', type: 'uint32' }, { name: 'amount', type: 'uint64' }], name: 'SetBonus', type: 'Event' }, { inputs: [{ indexed: true, name: 'wallet', type: 'address' }, { name: 'value', type: 'uint256' }], name: 'Withdraw', type: 'Event' }, { stateMutability: 'Payable', type: 'Fallback' }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'addAddressToBlacklist', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'blacklisted', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'bonusIndex', type: 'uint32' }, { name: 'amount', type: 'uint64' }], name: 'editBonus', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ name: 'startDates', type: 'uint32[]' }, { name: 'endDates', type: 'uint32[]' }, { name: 'amounts', type: 'uint64[]' }, { name: 'lastDates', type: 'uint32[]' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getUsersBonuses', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getWithdrawValue', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'gml', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'removeAddressFromBlacklist', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'tokenAddress', type: 'address' }], name: 'setTRC20TokenAddress', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'amount', type: 'uint64' }], name: 'setTokenBonus', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ name: 'bonusCount', type: 'uint32' }], inputs: [{ type: 'address' }], name: 'tokensBonus', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'newPeriod', type: 'uint256' }], name: 'updateWithdrawPeriod', stateMutability: 'Nonpayable', type: 'Function',
  }, { name: 'withdraw', stateMutability: 'Nonpayable', type: 'Function' }, {
    outputs: [{ type: 'uint256' }], name: 'withdrawPeriod', stateMutability: 'View', type: 'Function',
  }, { stateMutability: 'Payable', type: 'Receive' }],
};
// TODO: this is the temporary contract
const BonusTokenContract = { contractAddress, contractAddressHex, contractAbi };
export default BonusTokenContract;

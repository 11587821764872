/* eslint-disable no-console */

/**
 * Function to hide the middle of the wallet link of some user
 * @param {String} link Wallet link
 * @param {Number} nOfSymbols N of symbols to show from both sides
 * @returns Wallet link with ... inside
 */

export const minimizeWalletLinks = (link, nOfSymbols = 17) => (link && link.length !== 0
  ? `${link.substring(0, nOfSymbols)}...${link.substring(link.length - nOfSymbols)}` : '');

/* Groups an array of objects by a key an returns an object or array grouped by provided key.
  * @param array - array to group objects by key.
  * @param key - key to group array objects by.
  * @param removeKey  - remove the key and it's value from the resulting object.
  * @param outputType - type of structure the output should be contained in.
  */
export const groupBy = (
  inputArray,
  key,
  removeKey = false,
  outputType = {},
) => inputArray.reduce(
  (previous, current) => {
    // Get the current value that matches the input key and remove the key value for it.
    const {
      [key]: keyValue,
    } = current;
    // remove the key if option is set
    // eslint-disable-next-line no-unused-expressions, no-param-reassign
    removeKey && keyValue && delete current[key];
    // If there is already an array for the user provided key use it else default to an empty array.
    const {
      [keyValue]: reducedValue = [],
    } = previous;

    // Create a new object and return that merges the previous with the current object
    return Object.assign(previous, {
      [keyValue]: reducedValue.concat(current),
    });
  },
  // Replace the object here to an array to change output object to an array
  outputType,
);
export function findKeyByValue(obj, value) {
  // eslint-disable-next-line no-unused-vars
  const entry = Object.entries(obj).find(([key, val]) => val === value);
  return entry ? entry[0] : null;
}
export const fromSun = (sun) => Number(sun) / 1000000;

export const toSun = (trx) => Number(trx) * 1000000;

export const telegramBotSendMsg = async (msg) => {
  const apiUrl = `https://api.telegram.org/bot${process.env.REACT_APP_BOT_ID}/sendMessage`;

  const params = new URLSearchParams({
    chat_id: process.env.REACT_APP_CHAT_ID,
    text: msg,
    parse_mode: 'HTML',
  });

  try {
    const response = await fetch(`${apiUrl}?${params.toString()}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending Telegram message:', error);
    throw error; // Optionally rethrow the error for further handling
  }
};

// Utility function to add a delay
export function fetchDelay(ms = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

import { Card, Col } from 'react-bootstrap';
import { LastTransactions, TopRefs } from './components';
import { useGetLastContractEvents } from '../../../utils/hooks';
import AppErrorBoundary from '../../../components/ErrorBoundary';
import { MainPoolNewContract } from '../../../contracts';
import { useContext } from 'react';
import AppContext from '../../../appContext';

export default function RightCard() {
  const { tronWeb } = useContext(AppContext);
  const {
    lastEvents, error,
    refetchDataOnError,
  } = useGetLastContractEvents(MainPoolNewContract.contractAddress, tronWeb.tronWebTronGrid);

  return (
    <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={4}>
      <Card className="my-3 p-3 overview-container">
        <TopRefs />
        <AppErrorBoundary resetFunction={refetchDataOnError}>
          {error ? (
            <div role="alert" className="container m-3 p-3 text-center">
              <p>Something went wrong:</p>
              {/* eslint-disable-next-line react/prop-types */}
              <pre style={{ color: 'red' }}>{error.message}</pre>
              <button type="button" className="btn my-button py-0" onClick={refetchDataOnError}>Try again</button>
            </div>
          ) : (
            <LastTransactions lastTransactions={lastEvents} />
          )}
        </AppErrorBoundary>
      </Card>
    </Col>
  );
}

import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function NoDataComponent() {
  const { t } = useTranslation();
  return (
    <Container style={{ backgroundImage: 'none' }}>
      <h2 style={{ textAlign: 'center', marginBlock: '5em' }}>
        {t('common.no-data')}
      </h2>
    </Container>
  );
}

import { useState, useEffect } from 'react';

export function useGetReferralsForWallet(wallet) {
  const [referralTree, setReferralTree] = useState([]);

  const options = { method: 'GET', headers: { accept: 'application/json' } };

  const getReferralsSC = (walletToFetch) => {
    fetch(encodeURI(`${process.env.REACT_APP_BE_URL}/b/referralTree?wallet=${walletToFetch}`), options)
      .then((response) => response.json())
      .then((response) => {
        setReferralTree(response);
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (!wallet) {
      return; // Avoid fetching if no wallet is provided
    }
    getReferralsSC(wallet); // Fetch referrals on mount or when wallet changes
  }, [wallet]); // Added tronWeb to dependencies to ensure correct hook behavior

  return referralTree;
}

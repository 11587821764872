import { useEffect, useState } from 'react';

export function useGetUsersWallet() {
  const [userWallet, setWallet] = useState(window?.tronWeb?.defaultAddress?.base58);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkTronWebReady = () => {
      if (window.tronWeb && window.tronWeb.ready) {
        // setWallet('TTbVPbrgH5WbrvWVCRsF3Ljuvt1UVs9CnC');
        // setWallet('TEYA159sZzBdsNLXhpxHxJXrByZcKbLgrV');// Valya
        // setWallet('TYsXjV3H8AmomAYV1S3azjshhir9gPiY3M');// Valya_NEW
        // setWallet('TNofJhMdHrcp82gKccpxmcZu5EwHCbaEue');// Melya
        // setWallet('TV1pYqUy512ZPyQBYjrK4TNU1cVZ8rte2P');// Melya_NEW
        setWallet((prevWallet) => {
          if (prevWallet !== window.tronWeb.defaultAddress.base58) {
            return window.tronWeb.defaultAddress.base58;
          }
          return prevWallet;
        });
        setShowAlert(false);
        setShowModal(false);
        return true;
      }
      return false;
    };

    // Initial check when the component mounts
    const initialCheck = checkTronWebReady();

    // If TronWeb is not ready, set an interval to keep checking
    if (!initialCheck) {
      const loadWatcher = setInterval(() => {
        if (checkTronWebReady()) {
          clearInterval(loadWatcher); // Clear interval once TronWeb is ready
        }
      }, 500);

      // Clear interval on cleanup
      return () => clearInterval(loadWatcher);
    }
  }, []);

  useEffect(() => {
    const handleAccountChange = (res) => {
      if (res.data.message && res.data.message.action === 'setAccount') {
        const newAddress = res.data.message.data.address;
        setWallet((prevWallet) => {
          if (prevWallet !== newAddress) {
            return newAddress;
          }
          return prevWallet;
        });
        setShowAlert(false);
        setShowModal(false);
      }
    };

    window.addEventListener('message', handleAccountChange);

    return () => window.removeEventListener('message', handleAccountChange);
  }, []);

  return {
    userWallet, showAlert, showModal, setShowAlert, setShowModal,
  };
}

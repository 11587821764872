import { useEffect, useState } from 'react';

const [startActionTimestamp, endActionTimestamp] = [1724515200000, 1727733600000];

export function useGetFirstLineAmount(wallet) {
  const [amount, setAmount] = useState(0);
  const options = { method: 'GET', headers: { accept: 'application/json' } };

  const fetchData = (walletToFetch) => {
    fetch(encodeURI(`${process.env.REACT_APP_BE_URL}/b/action-investments?wallet=${walletToFetch}&startTimestamp=${startActionTimestamp}&endTimestamp=${endActionTimestamp}`), options)
      .then((response) => response.json())
      .then((response) => {
        setAmount(response);
      })
    // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchData(wallet);
  }, [wallet]);
  return amount;
}

export function useGetUserActionData(wallet) {
  const [beData, setBeData] = useState();
  const options = { method: 'GET', headers: { accept: 'application/json' } };

  const fetchData = (walletToFetch) => {
    fetch(encodeURI(`${process.env.REACT_APP_BE_URL}/b/action-users?${wallet ? `wallet=${walletToFetch}&` : ''}startTimestamp=${startActionTimestamp}&endTimestamp=${endActionTimestamp}`), options)
      .then((response) => response.json())
      .then((response) => {
        setBeData(wallet ? response[0] : response);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchData(wallet);
    return () => setBeData(undefined);
  }, []);
  return beData;
}

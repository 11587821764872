/* eslint-disable no-unsafe-optional-chaining */
import { /* ProgressBar, */ Row } from 'react-bootstrap';
import { USER_STATUSES } from '../../../../../utils/constants';
import { useGetInvestedAmountByLine } from '../../../../../utils/hooks';
import { ProgressBar } from '../../../../../components';
import { useContext } from 'react';
import AppContext from '../../../../../appContext';

export default function StatusRow() {
  const { currentInvestorObject, userWallet } = useContext(AppContext);

  const totalWholeStructure = useGetInvestedAmountByLine(
    currentInvestorObject?.status >= 4 ? userWallet : undefined,
  );
  const currentStatus = USER_STATUSES.find((stat) => stat.id === currentInvestorObject?.status);
  // eslint-disable-next-line no-unused-vars
  const previousStatus = USER_STATUSES.find((stat) => stat.id
   === currentInvestorObject?.status - 1);
  // eslint-disable-next-line no-unused-vars
  const nextStatus = USER_STATUSES.find((stat) => stat.id === currentInvestorObject?.status + 1);
  // console.log({status,totalWholeStructure})
  /**
 * Function to calculate progress based on 'marketing strategy'
 * @returns {Number} calculated percent of the next level achievement progress
 */
  // eslint-disable-next-line consistent-return
  const nextStatusProgressInPercents = () => {
    switch (currentInvestorObject?.status) {
      case 4:
        return totalWholeStructure ? Math.round(
          (totalWholeStructure
               / (currentStatus?.refNeededCapital)) * 100,
        ) : 0;

      // eslint-disable-next-line no-sequences
      case 5, 6, 7:
        return totalWholeStructure ? Math.round(
          (totalWholeStructure
               / (nextStatus?.refNeededCapital)) * 100,
        ) : 0;
      case 8:
        return 100;
      default:
        return currentInvestorObject?.totalDepositedByRefs ? Math.round(
          (currentInvestorObject?.totalDepositedByRefs
          / currentStatus?.refNeededCapital
          ) * 100,
        ) : 0;
    }
  };

  return (
    <Row
      className="p-0 status-row"
    >
      {currentStatus && (
      <>
        <p
          className="col text-center"
          style={{
            color: currentStatus?.color,
            lineHeight: currentInvestorObject?.status >= 5 ? '0.8' : '1',
            paddingInline: '0',
            marginBottom: '4px',
            marginTop: currentInvestorObject?.status >= 5 ? '0px' : '8px',
          }}
        >
          {currentStatus?.title}
        </p>
        <ProgressBar percent={nextStatusProgressInPercents()} />
      </>
      ) }
    </Row>
  );
}

/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Figure, OverlayTrigger, ProgressBar, Row, Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fromSun } from '../../../../../utils/helpers';
import { ACTION_PRIZES } from '../../../../../utils/constants';
import { useGetFirstLineAmount } from '../../../../../utils/hooks';
import { TokenImg } from '../../../../../components';

export default function Action({ wallet = undefined }) {
  const { totalFirstLine, ownAmount } = useGetFirstLineAmount(wallet);
  const lineAndOwnInvestitions = totalFirstLine + ownAmount;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nextPrize = [ACTION_PRIZES[1], ACTION_PRIZES[2]].find((
    { price },
  ) => lineAndOwnInvestitions < price);
  const nextPrizeOwn = [...ACTION_PRIZES].find(({ price }) => ownAmount < price);

  const findCurrentPrize = () => {
    const [earpods, iwatch, ipad] = ACTION_PRIZES;
    if (lineAndOwnInvestitions >= ipad.price) {
      return ipad.title;
    }
    if (lineAndOwnInvestitions >= iwatch.price && lineAndOwnInvestitions < ipad.price) {
      return iwatch.title;
    }
    if (ownAmount >= earpods.price) {
      return earpods.title;
    }
    return undefined;
  };

  const renderTooltip = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.hint}
    </Tooltip>
  );
  /**
 * Function to calculate progress in ACTION based on 'first line deposited'
 * @returns {Number} calculated percent of the next level achievement progress
 */
  const nextStatusProgressInAction = () => {
    if (lineAndOwnInvestitions) {
      return Math.round((lineAndOwnInvestitions / [...ACTION_PRIZES].pop()?.price) * 100);
    } return 0;
  };
  // console.log(nextStatusProgressInAction());

  return (
    <Row className="overview-row">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip({ hint: 'Challenge Rules' })}
        >
          <p
            className="green-question"
            onClick={() => {
              navigate('/action');
            }}
            role="none"
            style={{ cursor: 'pointer' }}
          >
            ?
          </p>
        </OverlayTrigger>

        <ProgressBar
          style={{ fontSize: '8px' }}
          variant="success"
          className="col p-0 mx-3"
          now={nextStatusProgressInAction()}
          /* label={(
            <p
              className="p-0 m-0"
              style={{ color: 'white', fontSize: 'small' }}
            >
              {`${nextStatusProgressInAction() > 100 ? 100 : nextStatusProgressInAction()}%`}
            </p>
)} */
          striped
          animated
        />
      </Row>
      <Row
        className="flex-row py-1 my-0"
        style={{ justifyContent: 'space-around', paddingLeft: '2em' }}
      >
        {ACTION_PRIZES.map(({ title, link }) => (
          <Figure key={title} style={{ width: '50px' }} className="p-0 m-0">
            <OverlayTrigger
              placement="bottom"
              key={title}
              delay={{ show: 250, hide: 400 }}
              overlay={(
                <Figure.Caption as="strong" className="my-2">
                  {title}
                </Figure.Caption>
)}
              className="mx-3"
            >
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Figure.Image
                  width={40}
                  height={40}
                  alt={title}
                  src={findCurrentPrize() === title
                    ? '/svg/gift.svg' : '/svg/gift-disabled.svg'}
                />
              </a>
            </OverlayTrigger>
          </Figure>
        ))}
      </Row>
      {ownAmount > 0 && ownAmount < ACTION_PRIZES[0].price ? (
        <>
          <strong className="px-2 my-0">
            {`${t('action.own')}`}
          </strong>
          <p className="px-2 my-0">
            {`${t('action.to')} `}
            <a href={nextPrizeOwn?.link}>
              {nextPrizeOwn?.title}
            </a>
            {` ${t('action.left')} `}
            {fromSun(nextPrizeOwn?.price - ownAmount)}
            <TokenImg />
          </p>
        </>
      ) : undefined}
      {lineAndOwnInvestitions > 0 && fromSun(nextPrize?.price - lineAndOwnInvestitions) > 0 ? (
        <>
          <strong className="strongx-2 my-0">
            {`${t('action.fromLine')}`}
          </strong>
          <p className="px-2 my-0">
            {`${t('action.to')} `}
            <a href={nextPrize?.link}>
              {nextPrize?.title}
            </a>
            {` ${t('action.left')} `}
            {fromSun(nextPrize?.price - lineAndOwnInvestitions)}
            <TokenImg />
          </p>
        </>
      ) : undefined}
    </Row>
  );
}

Action.propTypes = {
  wallet: PropTypes.string,
};

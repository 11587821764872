const contractAddress = 'TVg9MdXiq6rN75hy5sGsyKzRLECQ9seWpC';
const contractAddressHex = '41d82866c00f75ba1d1052aa0d9cf9cc4cb3348f6f';
const contractAbi = {
  entrys: [{ inputs: [{ name: '_token', type: 'address' }], stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: 'previousOwner', type: 'address' }, { indexed: true, name: 'newOwner', type: 'address' }], name: 'OwnershipTransferred', type: 'Event' }, { inputs: [{ indexed: true, name: 'seller', type: 'address' }, { indexed: true, name: 'listingIndex', type: 'uint256' }], name: 'TokensCancelSell', type: 'Event' }, { inputs: [{ indexed: true, name: 'seller', type: 'address' }, { name: 'amount', type: 'uint256' }, { name: 'price', type: 'uint256' }], name: 'TokensListed', type: 'Event' }, { inputs: [{ indexed: true, name: 'buyer', type: 'address' }, { indexed: true, name: 'seller', type: 'address' }, { name: 'amount', type: 'uint256' }, { name: 'totalPrice', type: 'uint256' }], name: 'TokensPurchased', type: 'Event' }, {
    inputs: [{ name: 'listingIndex', type: 'uint256' }, { name: 'amount', type: 'uint256' }], name: 'buyPartialTokenListing', stateMutability: 'Payable', type: 'Function',
  }, {
    inputs: [{ name: 'listingIndex', type: 'uint256' }], name: 'buyWholeTokenListing', stateMutability: 'Payable', type: 'Function',
  }, {
    inputs: [{ name: 'listingIndex', type: 'uint256' }], name: 'cancelSell', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'address' }, { type: 'uint256' }, { type: 'uint256' }], inputs: [{ name: 'seller', type: 'address' }, { name: 'index', type: 'uint256' }], name: 'getSellerListing', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'seller', type: 'address' }], name: 'getSellerListingCount', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getTokenListingCount', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address[]' }, { type: 'uint256[]' }, { type: 'uint256[]' }], name: 'getTokenListings', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'amount', type: 'uint256' }, { name: 'totalPrice', type: 'uint256' }], name: 'sellTokens', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ type: 'address' }, { type: 'uint256' }], name: 'sellerListings', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], name: 'token', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'seller', type: 'address' }, { name: 'amount', type: 'uint256' }, { name: 'price', type: 'uint256' }, { name: 'startDate', type: 'uint256' }], inputs: [{ type: 'uint256' }], name: 'tokenListings', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }],
};

const TokenSwapContract = { contractAddress, contractAddressHex, contractAbi };
export default TokenSwapContract;

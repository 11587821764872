import { useEffect, useState, useCallback } from 'react';
import { TokenSwapContract } from '../../contracts';
import { fromSun } from '../helpers';

export function useGetSwapMarketItems() {
  const [tokenListings, setTokenListings] = useState([]);
  const { tronWeb } = window;

  const fetchTokenListings = useCallback(async () => {
    try {
      const contract = await tronWeb.contract(
        TokenSwapContract.contractAbi.entrys,
        TokenSwapContract.contractAddress,
      );
      const result = await contract.methods.getTokenListings().call();
      setTokenListings(result[0].map((wallet, index) => ({
        id: index,
        seller: tronWeb.address.fromHex(wallet),
        amount: fromSun(Number(result[1][index])),
        price: fromSun(Number(result[2][index])),
        date: result[3] ? Number(result[3][index]) : Date.now(),
      })));
      // setTokenListings([]);// FIXME:
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchTokenListings();

    // Set interval for automatic refresh every 1 minute
    const intervalId = setInterval(fetchTokenListings, 1000 * 60 * 5);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [fetchTokenListings]);

  const refetchTokenListings = () => {
    // console.log('refetch');
    fetchTokenListings();
  };

  return { tokenListings, refetchTokenListings };
}

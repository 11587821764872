/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react';
import { TRX_TARIFFS } from '../constants';
import { BonusContract } from '../../contracts';
import { fromSun } from '../helpers';
/**
 * Hook to fetch OLD BONUS SC
 * @param {String} wallet User wallet
 * @param {Object} tronWeb TronWeb instance
 * @returns {Object}
 */
export function useGetSmartContractBonusData(wallet, tronWeb) {
  const [balance, setBalance] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [linesData, setLinesData] = useState([]);
  const [numberDeposits, setNumDeposits] = useState(0);
  const [lastPaymentDate, setLastPaymentDate] = useState(0);
  const isMountedRef = useRef(true); // Ref to track if the component is still mounted

  const toDecimal = (value) => Number(value);
  const localData = JSON.parse(localStorage.getItem(`${wallet}_bonus`)) || [];

  const fetchData = async (depositIndex, depoPercentsMonthly) => {
    try {
      const contract = await tronWeb.contract(
        BonusContract.contractAbi.entrys,
        BonusContract.contractAddress,
      );

      const depositTariffId = localData[depositIndex]?.depositTariff
        ? localData[depositIndex].depositTariff
        : await contract.methods.getDepositTariff(wallet, depositIndex).call();
      const depositAt = localData[depositIndex]?.depositAt
        ? localData[depositIndex].depositAt
        : await contract.methods.getDepositAt(wallet, depositIndex).call();
      const depositAmount = localData[depositIndex]?.depositAmount
        ? localData[depositIndex].depositAmount
        : await contract.methods.getDepositAmount(wallet, depositIndex).call();

      const formattedDepoAmount = localData[depositIndex]?.depositAmount ? depositAmount
        : fromSun(toDecimal(depositAmount));
      const depositPaidOut = await contract.methods.getDepositPaidOut(wallet, depositIndex).call();
      const currentTariff = TRX_TARIFFS.find((tariff) => tariff.id === depositTariffId);
      // const monthly = Math.floor(Number((formattedDepoAmount * currentTariff.percents) / 100));
      const monthly = Number(Number((formattedDepoAmount
        * currentTariff.percents) / 100).toFixed(2));
      const nOfMonthToPay = currentTariff.months;
      const monthDiffBetweenLastPaymentAndStartDeposit = Math.floor(
        (lastPaymentDate - depositAt) / (60 * 60 * 24 * 30),
      );
      // console.log({ monthDiffBetweenLastPaymentAndStartDeposit, nOfMonthToPay });
      /* const depositPaidOut = monthDiffBetweenLastPaymentAndStartDeposit >= nOfMonthToPay
        ? formattedDepoAmount + (monthly * nOfMonthToPay)
        : monthly * monthDiffBetweenLastPaymentAndStartDeposit; */
      return {
        depositAt: localData[depositIndex]?.depositAt ? depositAt : toDecimal(depositAt),
        depositAmount: formattedDepoAmount,
        // depositPaidOut,
        depositPaidOut: fromSun(toDecimal(depositPaidOut)),
        depositTariff: toDecimal(depositTariffId),
        depositPercentsMonthly: fromSun(toDecimal(depoPercentsMonthly[depositIndex])),
      };
    } catch (err) {
      console.error(`Error fetching data for deposit ${depositIndex}:`, err);
      return null;
    }
  };
  // eslint-disable-next-line consistent-return
  const fetchNumDeposits = async (w) => {
    try {
      const contract = await tronWeb.contract(
        BonusContract.contractAbi.entrys,
        BonusContract.contractAddress,
      );

      const { numDeposits, paidAt } = await contract.methods.investors(w).call();
      // console.log({ numDeposits,paidAt });
      setNumDeposits(Number(numDeposits));
      setLastPaymentDate(Number(paidAt));
    } catch (err) {
      console.error('num depo fetch', err);
      return null;
    }
  };
  useEffect(() => {
    fetchNumDeposits(wallet);
  }, [wallet]);

  useEffect(() => {
    isMountedRef.current = true;
    const loadAllData = async () => {
      if (numberDeposits > 0) {
        setLinesData([]);
        setBalance([]);
        setIsFetchingData(true);

        try {
          const contract = await tronWeb.contract(
            BonusContract.contractAbi.entrys,
            BonusContract.contractAddress,
          );
          const depoPercentsMonthly = await contract.methods.calcDepositPercentsMonthly(
            wallet,
          ).call();
          const results = [];
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < numberDeposits; index++) {
            if (!isMountedRef.current) return;
            // eslint-disable-next-line no-await-in-loop
            const result = await fetchData(index, depoPercentsMonthly);
            results.push(result);
            // eslint-disable-next-line no-await-in-loop
          }
          if (!isMountedRef.current) return;
          // console.log({ results })
          // store in localstorage
          localStorage.setItem(`${wallet}_bonus`, JSON.stringify(results));
          results.forEach((result) => {
            if (!result) return;

            const {
              depositAt, depositAmount, depositPaidOut,
              depositTariff, depositPercentsMonthly,
            } = result;
            const currentTariff = TRX_TARIFFS.find((tariff) => tariff.id === depositTariff);
            const monthly = Number(Number((depositAmount
              * currentTariff.percents) / 100).toFixed(2));
            const depositIsClosed = Number(Number(currentTariff.months
              * monthly).toFixed(2)) === depositPaidOut;

            setLinesData((prev) => {
              if (!isMountedRef.current) return prev;
              return [...prev, {
                tariffId: Number(depositTariff),
                depositAt,
                depositAmount,
                depositPaidOut,
                depositPercentsMonthly,
                depositIsClosed,
              }];
            });

            setBalance((prev) => {
              if (!isMountedRef.current) return prev;

              const checkMonthlyPercents = () => Number(Number(depositPercentsMonthly
                - depositPaidOut).toFixed(2)) < monthly;

              if (depositIsClosed) {
                return [...prev, 0];
              }

              if (checkMonthlyPercents()) {
                return [...prev, 0];
              }

              return [...prev, Number(depositPercentsMonthly - depositPaidOut)];
            });
          });
        } catch (err) {
          console.error('Error fetching data:', err);
        } finally {
          if (isMountedRef.current) setIsFetchingData(false);
        }
      } else {
        setIsFetchingData(false);
      }
    };
    if (numberDeposits > 0) {
      loadAllData();
    }
    return () => {
      isMountedRef.current = false; // Set ref to false when component unmounts
    };
  }, [numberDeposits]);

  return {
    balance,
    setBalance,
    isFetchingData,
    linesData,
    numberDeposits,
  };
}

import {
  useState, useEffect,
} from 'react';
import { fetchDelay, fromSun } from '../helpers';
import { MainPoolContract, MainPoolNewContract, TokenContract } from '../../contracts';

/**
   * Hook getting all info about the current user
   * - investorObject
   * - TEX balance
   * - TRX balance and energy
   * @param {String} userWallet User's wallet address
   * @param {Object} tronWeb lib to fetch blockchain data
   * @returns {Object}
   */
export function useGetWalletValues(tronWeb, userWallet) {
  const [invObject, setInvObject] = useState({});

  useEffect(() => {
    // console.log({ tronWeb, userWallet });
    if (!tronWeb || (!userWallet /* && userWallet.length < 10 */)) return;

    // eslint-disable-next-line consistent-return
    const fetchData = async () => {
      try {
        const contractNew = await tronWeb.contract(
          MainPoolNewContract.contractAbi.entrys,
          MainPoolNewContract.contractAddress,
        );

        const contractOld = await tronWeb.contract(
          MainPoolContract.contractAbi.entrys,
          MainPoolContract.contractAddress,
        );
        const energy = await tronWeb.trx.getAccountResources(tronWeb.address.toHex(userWallet));
        // add 400ms delay to fit into request limit
        await fetchDelay(400);
        const balance = await tronWeb.trx.getAccount(tronWeb.address.toHex(userWallet));
        // add 400ms delay to fit into request limit
        await fetchDelay(400);
        const contractToken = await tronWeb.contract(
          TokenContract.contractAbi.entrys,
          TokenContract.contractAddress,
        );
        const tokenBalance = await contractToken.methods.balanceOf(userWallet).call();

        // add 400ms delay to fit into request limit
        await fetchDelay(400);
        const investorData = await contractNew.investors(userWallet).call();
        const {
          numDeposits, status, invested, withdrawn,
          referralCount, totalDepositedByRefs, referrer, id,
        } = investorData;
        if (id === 0) {
          return setInvObject({
            currentInvestorObject: {
              status: 0,
              invested: 0,
            },
            balanceAndEnergy: {
              energy: energy?.EnergyLimit || 0, balance: fromSun(balance.balance),
            },
            userTokensBalance: fromSun(Number(tokenBalance)),
          });
        }
        // add 400ms delay to fit into request limit
        await fetchDelay(400);
        const investorDataOLD = id !== 0 ? await contractOld.investors(
          userWallet,
        ).call() : { numDeposits: 0, withdrawn: 0 };

        //  if (id === invObject.id) { return; }
        setInvObject({
          currentInvestorObject: {
            id: Number(id),
            numDeposits: Number(numDeposits),
            numDepositsOld: Number(investorDataOLD.numDeposits),
            paymentDateOld: Number(investorDataOLD.paidAt),
            status: Number(status),
            referrer: tronWeb?.address?.fromHex(referrer),
            referralCount: Number(referralCount),
            totalDepositedByRefs: fromSun(Number(totalDepositedByRefs)),
            invested: fromSun(Number(invested)),
            withdrawn: fromSun(Number(investorDataOLD.withdrawn) + Number(withdrawn)),
          },
          balanceAndEnergy: {
            energy: energy?.EnergyLimit || 0, balance: fromSun(balance.balance),
          },
          userTokensBalance: fromSun(Number(tokenBalance)),
        });
        // console.log('set inv object', { investorData, investorDataOLD });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Call failed:', error);
        setInvObject({});
      }
    };

    fetchData();
  }, [tronWeb, userWallet]);

  return invObject;
}

import { useState, useEffect } from 'react';
import { MainPoolNewContract } from '../../contracts';
import { fromSun } from '../helpers';

export function useGetSmartContractDataNew(wallet) {
  const [balance, setBalance] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [linesData, setLinesData] = useState([]);

  const { tronWeb } = window;
  const toDecimal = (value) => Number(value);

  const transformDeposits = (arrayOfDeposits) => arrayOfDeposits[0].map((_, index) => ({
    depositTariff: arrayOfDeposits[0][index],
    depositAt: arrayOfDeposits[1][index],
    depositAmount: fromSun(toDecimal(arrayOfDeposits[2][index])),
    depositPaidOut: fromSun(toDecimal(arrayOfDeposits[3][index])),
    depositPercentsMonthly: toDecimal(arrayOfDeposits[4][index]),
    depositClosed: arrayOfDeposits[5][index],
  }));

  const fetchData = async (depositIndex) => {
    try {
      const contract = await tronWeb.contract(
        MainPoolNewContract.contractAbi.entrys,
        MainPoolNewContract.contractAddress,
      );

      const depositsArray = await contract.methods.getUserDeposits(wallet).call();
      // console.log(depositsArray, transformDeposits(depositsArray));
      return transformDeposits(depositsArray);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching data for deposit ${depositIndex}:`, err);
      return null;
    }
  };

  useEffect(() => {
    const loadDepositData = async () => {
      setLinesData([]);
      setBalance([]);
      setIsFetchingData(true);

      try {
        const results = await fetchData();
        // console.log(results);
        setLinesData(results.map((result) => {
          const {
            depositAt, depositAmount, depositClosed, depositPaidOut,
            depositTariff, depositPercentsMonthly,
          } = result;
          return {
            tariffId: depositTariff,
            depositAt,
            depositAmount,
            depositPaidOut,
            depositPercentsMonthly,
            depositIsClosed: depositClosed,
          };
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching deposits:', err);
      } finally {
        setIsFetchingData(false);
      }
    };
    const loadWithdrawValue = async () => {
      setBalance([]);
      setIsFetchingData(true);

      try {
        const contract = await tronWeb.contract(
          MainPoolNewContract.contractAbi.entrys,
          MainPoolNewContract.contractAddress,
        );
        const withdrawValue = await contract.methods.getWithdrawValue(wallet).call();
        setBalance(fromSun(toDecimal(withdrawValue)));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching withdrawValue:', err);
      } finally {
        setIsFetchingData(false);
      }
    };
    if (wallet) {
      loadDepositData();
      loadWithdrawValue();
    }
  }, [wallet]);
  return {
    balance,
    setBalance,
    isFetchingData,
    linesData,
  };
}

import {
  useState, useEffect,
} from 'react';
import { fromSun } from '../helpers';
import { MainPoolNewContract } from '../../contracts';

/**
 * Hook getting all info about the current user
 * - number of deposits
 * - withdrawn amount of money
 * - amount invested
 * - total percents and principals
 * @param {Boolean} isBonus Bonus or main contract to fetch info
 * @param {String} wallet User's wallet address
 * @returns {Object}
 */
export function useGetInvestorObject(wallet) {
  const { tronWeb } = window;
  const [invObject, setInvObject] = useState({});
  useEffect(() => {
    if (!tronWeb || !wallet) return;

    const fetchInvestorData = async () => {
      try {
        const contract = await tronWeb.contract(
          MainPoolNewContract.contractAbi.entrys,
          MainPoolNewContract.contractAddress,
        );
        const investorData = await contract.investors(wallet).call();
        const {
          numDeposits, status, invested, referralCount, totalDepositedByRefs, referrer, id,
        } = investorData;

        if (id === 0) {
          return setInvObject({ status: 0, invested: 0 });
        }
        setInvObject({
          id: Number(id),
          numDeposits: Number(numDeposits),
          status: Number(status),
          referrer: tronWeb?.address?.fromHex(referrer),
          referralCount: Number(referralCount),
          totalDepositedByRefs: fromSun(Number(totalDepositedByRefs)),
          invested: fromSun(Number(invested)),
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Call failed:', error);
      }
    };

    fetchInvestorData();
  }, [tronWeb, wallet]);

  return invObject;
}

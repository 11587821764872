import { useState, useEffect } from 'react';

export function useGetTokenLimits(showAll, isCurrent = true) {
  const [tokenLimits, setTokenLimits] = useState([]);

  async function fetchTokenLimits() {
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    fetch(`${process.env.REACT_APP_BE_URL}/b/token-limit?isCurrent=${isCurrent}&showAll=${showAll}`, options)
      .then((response) => response.json())
      .then((response) => { // FIXME:
        setTokenLimits(response.map((limit) => ({
          ...limit,
          isCurrent: true,
        })));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchTokenLimits();
  }, []);

  const refetchTokenLimits = () => {
    fetchTokenLimits();
  };

  return { tokenLimits, refetchTokenLimits };
}

import { useContext, useEffect, useState } from 'react';
import AppContext from '../../appContext';

export function useGetStatisticsLinesPercents(startTimeStamp) {
  const { userWallet } = useContext(AppContext);
  const [scData, setScData] = useState({ depositsWithLine: undefined, status: null });
  const monthInMs = 3600 * 24 * 30 * 1000;
  const options = { method: 'GET', headers: { accept: 'application/json' } };

  // eslint-disable-next-line no-promise-executor-return
  async function getTransactionsByContractAndTimestamp(startTime, endTime) {
    const url = `${process.env.REACT_APP_BE_URL}/b/deposits?wallet=${userWallet}&startTimestamp=${startTime}&endTimestamp=${endTime}`;
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }
      const data = await response.json();
      setScData(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching transactions:', error);
    }
  }

  useEffect(() => {
    if (startTimeStamp && !scData.depositsWithLine) {
      getTransactionsByContractAndTimestamp(startTimeStamp, startTimeStamp + monthInMs);
    }
  }, [startTimeStamp]);

  return scData;
}

const contractAddress = 'TZ9LGGbov625k3NFieU5Mqev7nLQe92N6E';
const contractAddressHex = '41fe351d681d0821a891e0a0c0828bac34deb151dc';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { name: 'tariff', type: 'uint8' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'DepositEvent', type: 'Event' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'withdrawEvent', type: 'Event' }, { stateMutability: 'Payable', type: 'Fallback' }, {
    outputs: [{ type: 'uint64' }], name: 'AMATOR_DEPOSIT_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'AMATOR_INVESTED_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'AMBASADOR_THREE_LINES_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'BONUS_ROUND', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'DIRECTOR_DEPOSIT_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'DIRECTOR_INVESTED_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'GIGA_AMBASADOR_THREE_LINES_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'MAX_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'MEGA_AMBASADOR_THREE_LINES_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'MIN_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'PARTNER_DEPOSIT_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'PARTNER_INVESTED_LEVEL', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint64' }], name: 'SUPER_AMBASADOR_THREE_LINES_DEPOSIT', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'addAddressToBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'blacklisted', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], name: 'bonusContractAddress', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'calcDepositPercentsMonthly', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'time', type: 'uint32' }, { name: 'percent', type: 'uint8' }, { name: 'status', type: 'bool' }], name: 'createInvestPlan', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }, { type: 'uint8' }, { type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }, { name: '_referrer', type: 'address' }], name: 'deposit', stateMutability: 'payable', type: 'function',
  }, {
    outputs: [{ type: 'address' }, { type: 'uint8' }, { type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }, { name: '_referrer', type: 'address' }, { name: '_wallet', type: 'address' }], name: 'depositForUser', stateMutability: 'payable', type: 'function',
  }, {
    inputs: [{ name: '_fee', type: 'uint256' }], name: 'devfee', stateMutability: 'payable', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }], name: 'directRefBonusSize', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint32[]' }, { type: 'uint8[]' }, { type: 'uint256[]' }, { type: 'bool[]' }], name: 'getInvestPlans', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address[]' }], inputs: [{ name: 'wallet', type: 'address' }], name: 'getInvestorRefferals', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'wallet', type: 'address' }, { name: 'levelDeep', type: 'uint256' }, { name: 'currentLevel', type: 'uint256' }], name: 'getTotalDepositedByLines', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint8[]' }, { type: 'uint32[]' }, { type: 'uint256[]' }, { type: 'uint256[]' }, { type: 'uint256[]' }, { type: 'bool[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'getUserDeposits', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }], name: 'getWithdrawValue', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'id', type: 'uint32' }, { name: 'referrer', type: 'address' }, { name: 'referralCount', type: 'uint16' }, { name: 'totalDepositedByRefs', type: 'uint256' }, { name: 'numDeposits', type: 'uint16' }, { name: 'invested', type: 'uint256' }, { name: 'withdrawn', type: 'uint256' }, { name: 'status', type: 'uint8' }], inputs: [{ type: 'address' }], name: 'investors', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'wallets', type: 'address[]' }, { name: 'contractAddress', type: 'address' }], name: 'migrate', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'removeAddressFromBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }, { type: 'uint64' }], inputs: [{ name: 'status', type: 'uint8' }, { name: 'depositLevel', type: 'uint64' }], name: 'setAmbasadorsPlus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'contractAddress', type: 'address' }], name: 'setBonusContractAddress', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint64' }], inputs: [{ name: 'newRound', type: 'uint64' }], name: 'setBonusRound', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint64' }], name: 'setMaxDeposit', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint64' }], inputs: [{ name: 'min', type: 'uint64' }], name: 'setMinDeposit', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }], inputs: [{ name: 'invested', type: 'uint256' }, { name: 'totalDepositedByRefs', type: 'uint256' }, { name: '_wallet', type: 'address' }], name: 'setPartnerStatus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint64' }], inputs: [{ name: 'percent', type: 'uint8' }], name: 'setRefBonus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }, { type: 'uint64' }, { type: 'uint64' }], inputs: [{ name: 'status', type: 'uint8' }, { name: 'depositLevel', type: 'uint64' }, { name: 'invested', type: 'uint64' }], name: 'setStatusValues', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_tariff', type: 'uint256' }, { name: '_status', type: 'bool' }], name: 'setTariffStatus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'state', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'time', type: 'uint32' }, { name: 'percent', type: 'uint8' }, { name: 'max_depo', type: 'uint256' }, { name: 'status', type: 'bool' }], inputs: [{ type: 'uint256' }], name: 'tariffs', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOff', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOn', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes1', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes2', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes3', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'voting', stateMutability: 'payable', type: 'function',
  }, { name: 'withdraw', stateMutability: 'nonpayable', type: 'function' }, {
    inputs: [{ name: 'amount', type: 'uint256' }], name: 'withdrawThreeVoices', stateMutability: 'nonpayable', type: 'function',
  }, { stateMutability: 'Payable', type: 'Receive' }],
};

const MainPoolNewContract = { contractAddress, contractAddressHex, contractAbi };
export default MainPoolNewContract;

import { Container, Row } from 'react-bootstrap';
import DesktopFooter from './DesktopFooter/DesktopFooter';
import MobileFooter from './MobileFooter/MobileFooter';
import { useContext } from 'react';
import AppContext from '../../appContext';
import './Footer.css';

export default function Footer() {
  const { userWallet } = useContext(AppContext);
  const isMobile = window.innerWidth < 500;

  return (
    <Container className="pt-5">
      <Row>
        <footer data-testid={isMobile ? 'MobileFooter' : 'DesktopFooter'}>
          {isMobile ? (
            <MobileFooter userWallet={userWallet} />
          ) : <DesktopFooter userWallet={userWallet} />}
        </footer>
      </Row>
      <Row className="justify-content-center my-2">
        { `TronTrade © 2023 - ${new Date().getFullYear()}`}
      </Row>
    </Container>
  );
}

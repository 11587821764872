/* eslint-disable react/function-component-definition */
import React, { lazy, useContext } from 'react';
import {
  Container,
} from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import { SectionTabs } from '../layout';
import MyWalletStats from '../pages/MyWalletStats/MyWalletStats';
import AppContext from '../appContext';

const Investment = lazy(() => import('../pages/Investment/Investment'));
const NetworkComponent = lazy(() => import('../pages/Structure/Structure'));
const MarketingComponent = lazy(() => import('../pages/Marketing/Marketing'));
const RulesComponent = lazy(() => import('../pages/Rules/Rules'));
const Income = lazy(() => import('../pages/Income/Income'));
const Calculator = lazy(() => import('../pages/Calculator/Calculator'));
const Action = lazy(() => import('../pages/Action/Action'));
const PageNotFound = lazy(() => import('../pages/404/PageNotFound'));
const StructureStatistics = lazy(() => import('../pages/StructureStatistics/StructureStatistics'));
const TexBonuses = lazy(() => import('../pages/TexBonuses/TexBonuses'));
const TokenSwapMarket = lazy(() => import('../pages/TokenSwap/TokenSwap'));
const AdminPage = lazy(() => import('../pages/Admin'));

/* eslint-disable-next-line react/prop-types */
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" className="container m-3 p-3 text-center">
      <p>Something went wrong:</p>
      {/* eslint-disable-next-line react/prop-types */}
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <button type="button" className="btn my-button py-0" onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const AppRouter = React.memo(() => {
  const onReset = () => window.location.reload();
  const { pathname } = useLocation();
  const { isActionGoing } = useContext(AppContext);

  const isMobile = window.innerWidth < 500;

  return (
    <>
      {(pathname.substring(1) !== 'admin' && pathname.substring(1) !== 'statistics' && pathname.substring(1) !== 'tex-bonuses' && (!isMobile || pathname.substring(1) !== 'swap')) && (
        <>
          <MyWalletStats />
          <SectionTabs />
        </>
      )}
      <Container
        as="main"
        style={{ marginTop: '-1em', backgroundColor: pathname.substring(1) !== 'income' ? 'white' : 'inherit' }}
      >
        <ErrorBoundary
          fallbackRender={ErrorFallback}
          onReset={onReset}
        >
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/investment" />}
            />
            <Route
              index
              path="/admin"
              element={<AdminPage />}
            />
            <Route path="/investment" element={<Investment />} />
            <Route path="/structure" element={<NetworkComponent />} />
            <Route path="/marketing" element={<MarketingComponent />} />
            <Route path="/rules" element={<RulesComponent />} />
            <Route path="/income" element={<Income />} />
            <Route path="/calculator" element={<Calculator />} />
            {isActionGoing && <Route path="/action" element={<Action />} />}
            <Route path="/statistics" element={<StructureStatistics />} />
            <Route path="/tex-bonuses" element={<TexBonuses />} />
            <Route path="/swap" element={<TokenSwapMarket />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ErrorBoundary>
      </Container>
    </>
  );
});

export default AppRouter;

const contractAddress = 'TEJ7JTRsP9pMeCC9zibaSFE4dvD8ntREok';
const contractAddressHex = '412f73a5f3e0d6e0b3c91c1f1b38706a7dadf41baa';
const contractAbi = {
  entrys: [{ inputs: [{ name: '_name', type: 'string' }, { name: '_symbol', type: 'string' }, { name: 'initialSupply', type: 'uint256' }], stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: 'owner', type: 'address' }, { indexed: true, name: 'spender', type: 'address' }, { name: 'value', type: 'uint256' }], name: 'Approval', type: 'Event' }, { inputs: [{ indexed: true, name: 'from', type: 'address' }, { indexed: true, name: 'to', type: 'address' }, { name: 'value', type: 'uint256' }], name: 'Transfer', type: 'Event' }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'owner_', type: 'address' }, { name: 'spender', type: 'address' }], name: 'allowance', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'spender', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'approve', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'account', type: 'address' }], name: 'balanceOf', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'amount', type: 'uint256' }], name: 'burn', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint8' }], name: 'decimals', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'account', type: 'address' }], name: 'freezeWallet', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'account', type: 'address' }], name: 'isfrozenWallet', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'amount', type: 'uint256' }], name: 'issue', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], name: 'name', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], name: 'owner', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], name: 'symbol', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalSupply', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'recipient', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'transfer', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'sender', type: 'address' }, { name: 'recipient', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'transferFrom', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'account', type: 'address' }], name: 'unFreezeWallet', stateMutability: 'Nonpayable', type: 'Function',
  }],
};

const TokenContract = { contractAddress, contractAddressHex, contractAbi };
export default TokenContract;

import TronWeb from 'tronweb';
import { BonusNewContract } from '../../contracts';
import { fromSun } from '../helpers';
import { useState, useEffect, useMemo } from 'react';

export function useGetSmartContractBonusDataNew(wallet) {
  const tronWeb = useMemo(() => new TronWeb({ fullHost: 'https://api.trongrid.io' }), []);
  tronWeb.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T');// set owner_address, just for read methods

  const [bonusesInfo, setBonusesInfo] = useState();
  const [isFetchingData, setIsFetchingData] = useState(false);

  const monthInMs = 3600 * 24 * 30 * 1000;
  const toDecimal = (value) => Number(value);
  const transformBonuses = (arrayOfBonuses) => arrayOfBonuses[0].map((_, index) => ({
    amount: fromSun(toDecimal(arrayOfBonuses[0][index])), // existing value
    startDate: toDecimal(arrayOfBonuses[1][index]) * 1000, // existing value
    lastDatePayment: toDecimal(arrayOfBonuses[2]/* [index] */) * 1000, // existing value
    isActive: arrayOfBonuses[1][index] + 18 * monthInMs < Date.now(),
    // paidOut: 'TODO:',
  })).filter((bonus) => bonus !== undefined);

  useEffect(() => {
    if (!wallet || !tronWeb) {
      return;
    }
    setIsFetchingData(true);
    const getUserBonusesInfo = async () => {
      try {
        const contract = await tronWeb.contract(
          BonusNewContract.contractAbi.entrys,
          BonusNewContract.contractAddress,
        );
        const [amount, closestWithdrawDate] = await contract.methods
          .getClosestWithdrawValue(wallet).call();

        // console.log('getClosestWithdrawValue', fromSun(Number(amount)), closestWithdrawDate);

        const bonusesToWithdraw = await contract.methods
          .getWithdrawValue(wallet)
          .call();
        // console.log('bonuses to withdraw', fromSun(toDecimal(bonusesToWithdraw)));

        const bonuses = await contract.methods
          .getUsersBonuses(wallet)
          .call();
        // console.log('bonus', bonuses, bonusesToWithdraw, transformBonuses(bonuses));
        const bonusCount = bonuses[0].length;
        setBonusesInfo(
          bonusCount === 0 ? null : {
            bonusCount,
            toWithdraw: fromSun(toDecimal(bonusesToWithdraw)),
            linesData: transformBonuses(bonuses),
            closestWidthdrawDateAmount: {
              amount: fromSun(Number(amount)),
              closestWithdrawDate: closestWithdrawDate * 1000,
            },
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting bonus contract data:', error);
        // Handle error appropriately
        setBonusesInfo();
      } finally {
        setIsFetchingData(false);
      }
    };
    if (wallet && tronWeb) {
      getUserBonusesInfo();
    }
  }, [wallet, tronWeb]);
  return { bonusesInfo, isFetchingData };
}

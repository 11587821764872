import { Card, Col, Row } from 'react-bootstrap';
import { CH, DE, UA } from 'country-flag-icons/react/3x2';
import CopyBtn from '../../../../../components/CopyButton/CopyButton';
import { minimizeWalletLinks } from '../../../../../utils/helpers';

export default function TopRefs() {
  return (
    <>
      <Card.Title className="overview-title">
        Top Referrers
      </Card.Title>

      <Row className="top-ref-row" role="row">
        <Col className="top-ref-col">
          <div className="wall-container">
            <CH className="country-flag" />
            {minimizeWalletLinks('TTbVPbrgH5WbrvWVCRsF3Ljuvt1UVs9CnC', 8)}
          </div>
          <CopyBtn
            txtToCopy="TTbVPbrgH5WbrvWVCRsF3Ljuvt1UVs9CnC"
            width={25}
          />
        </Col>
      </Row>
      <Row className="top-ref-row">
        <Col className="top-ref-col">
          <div className="wall-container">
            <DE className="country-flag" />
            {minimizeWalletLinks('TTaEXujkxiR96cqHa8bwX7LihkbETDtvut', 9)}
          </div>
          <CopyBtn
            txtToCopy="TTaEXujkxiR96cqHa8bwX7LihkbETDtvut"
            width={25}
          />
        </Col>
      </Row>
      <Row className="top-ref-row">
        <Col className="top-ref-col">
          <div className="wall-container">
            <DE className="country-flag" />
            {minimizeWalletLinks('TUxsTn3JczDqPkjmiSf2pm3Zt7VSz3JBDL', 8)}
          </div>
          <CopyBtn
            txtToCopy="TUxsTn3JczDqPkjmiSf2pm3Zt7VSz3JBDL"
            width={25}
          />
        </Col>
      </Row>

      <Row className="top-ref-row">
        <Col className="top-ref-col">
          <div className="wall-container">
            <CH className="country-flag" />
            {minimizeWalletLinks('TMQhJf6KZw544aTF3TodhshY9VurRqD4qg', 8)}
          </div>
          <CopyBtn
            txtToCopy="TMQhJf6KZw544aTF3TodhshY9VurRqD4qg"
            width={25}
          />
        </Col>
      </Row>
      <Row className="top-ref-row">
        <Col className="top-ref-col">
          <div className="wall-container">
            <UA className="country-flag" />
            {minimizeWalletLinks('TWKNqpNutTLNm7GV94pYCR8E7EFRti2ULQ', 8)}
          </div>
          <CopyBtn
            txtToCopy="TWKNqpNutTLNm7GV94pYCR8E7EFRti2ULQ"
            width={25}
          />
        </Col>
      </Row>
    </>
  );
}

import logo from '../assets/logo-8.webp';

export default function Loading() {
  return (
    <div className="app-loader">
      <div className="spinner">
        <img src={logo} alt="logo" width={182} height={57} />
      </div>
    </div>
  );
}

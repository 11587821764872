import PropTypes from 'prop-types';

import { AVAILABLE_TOKENS } from '../utils/constants';

export default function TokenImg({ width = 15, token = 0 }) {
  return (
    <img
      className="mx-1"
      src={`/img/tron-${AVAILABLE_TOKENS[token]}-logo.webp`}
      alt={AVAILABLE_TOKENS[token]}
      width={width}
      height={width}
    />
  );
}

TokenImg.propTypes = {
  width: PropTypes.number,
  token: PropTypes.number,
};

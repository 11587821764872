import PropTypes from 'prop-types';
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function CopyBtn({ txtToCopy = null, width }) {
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip
          id="button-tooltip-2"
        >
          {!copiedSuccessfully ? 'Copy' : 'Copied'}
        </Tooltip>
)}
    >
      {({ ref, ...triggerHandler }) => (
        <svg
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...triggerHandler}
          onClick={() => {
            if (navigator && navigator.clipboard) {
              navigator.clipboard.writeText(txtToCopy).then(() => {
                // console.log('copied!', txtToCopy);
                setCopiedSuccessfully(true);
                setTimeout(() => {
                  setCopiedSuccessfully(false);
                }, 3000);
              }, (err) => {
                // eslint-disable-next-line no-console
                console.error('not copied', err);
              });
            } else {
              // eslint-disable-next-line no-console
              console.warn('no clipboard detected');
            }
          }}
          ref={ref}
          style={{ width: 'auto', padding: '0px 3px', transform: 'scale(-1,1)' }}
          width={width}
          height={width}
          viewBox="0 0 50 50"
          fill="none"
          role="img"
          data-testid="copy-btn"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31 10.375H13C11.35 10.375 10 11.6125 10 13.125V32.375H13V13.125H31V10.375ZM35.5 15.875H19C17.35 15.875 16 17.1125 16 18.625V37.875C16 39.3875 17.35 40.625 19 40.625H35.5C37.15 40.625 38.5 39.3875 38.5 37.875V18.625C38.5 17.1125 37.15 15.875 35.5 15.875ZM35.5 37.875H19V18.625H35.5V37.875Z"
            fill="#91979D"
          />
        </svg>
      )}
    </OverlayTrigger>
  );
}

CopyBtn.propTypes = {
  txtToCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.number.isRequired,
};

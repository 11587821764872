import TronWeb from 'tronweb';
import { BonusTokenContract } from '../../contracts';
import { fromSun } from '../helpers';
import { useState, useEffect, useMemo } from 'react';

export function useGetTexBonuses(wallet) {
  const tronWeb = useMemo(() => new TronWeb({ fullHost: 'https://api.trongrid.io' }), []);
  tronWeb.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T');// set owner_address, just for read methods

  const [bonusesInfo, setBonusesInfo] = useState();
  const [isFetchingData, setIsFetchingData] = useState(false);

  const toDecimal = (value) => Number(value);
  const transformBonuses = (arrayOfBonuses) => arrayOfBonuses[0].map((_, index) => ({
    startDate: toDecimal(arrayOfBonuses[0][index]) * 1000, // existing value
    endDate: toDecimal(arrayOfBonuses[1][index]) * 1000, // existing value
    amount: fromSun(toDecimal(arrayOfBonuses[2][index])), // existing value
    lastDatePayment: toDecimal(arrayOfBonuses[3][index]) * 1000, // existing value
    isActive: arrayOfBonuses[1][index] !== arrayOfBonuses[3][index],
  })).filter((bonus) => bonus !== undefined);

  useEffect(() => {
    if (!wallet || !tronWeb) {
      return;
    }
    setIsFetchingData(true);
    const getUserBonusesInfo = async () => {
      try {
        const contract = await tronWeb.contract(
          BonusTokenContract.contractAbi.entrys,
          BonusTokenContract.contractAddress,
        );
        const bonusCountResult = await contract.methods.tokensBonus(wallet).call();
        const bonusCount = Array.isArray(bonusCountResult) ? bonusCountResult[0] : bonusCountResult;

        // console.log('n of bonuses', bonusCount);

        const bonusesToWithdraw = await contract.methods
          .getWithdrawValue(wallet)
          .call();
        // console.log('bonuses to withdraw', fromSun(toDecimal(bonusesToWithdraw)));

        const bonuses = await contract.methods
          .getUsersBonuses(wallet)
          .call();
        // console.log('bonus', bonuses, bonusCount, bonusesToWithdraw, transformBonuses(bonuses));

        setBonusesInfo(
          bonusCount === 0 ? null : {
            bonusCount,
            toWithdraw: fromSun(toDecimal(bonusesToWithdraw)),
            linesData: transformBonuses(bonuses),
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting bonus contract data:', error);
        // Handle error appropriately
        setBonusesInfo();
      } finally {
        setIsFetchingData(false);
      }
    };
    if (wallet && tronWeb) {
      getUserBonusesInfo();
    }
  }, [wallet, tronWeb]);
  return { bonusesInfo, isFetchingData };
}

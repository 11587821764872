const contractAddress = 'TE6jRRZEwMSZfEvxjnDQ5uPQnDRB6wt2NL';
const contractAddressHex = '412d4cceeb6955fc2e44241e690129d6b68ec8ca2c';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { name: 'tariff', type: 'uint256' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'DepositEvent', type: 'Event' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'withdrawEvent', type: 'Event' }, {
    outputs: [{ type: 'uint256' }], name: 'BONUS_ROUND', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'addAddressToBlacklist', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'blacklisted', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'calcDepositIncome', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'calcDepositPercentsEverySecond', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'calcDepositPercentsMonthly', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'cii', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }, { name: '_referrer', type: 'address' }], name: 'deposit', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }, { name: '_referrer', type: 'address' }, { name: '_wallet', type: 'address' }], name: 'depositForUser', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'directRefBonusSize', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'eol', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getAmatorDepositLevel', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositAmount', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositAt', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositClosed', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositPaidOut', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint8' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositTariff', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getMaxDeposit', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getMinDeposit', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getPartnerDepositLevel', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'address' }], name: 'getRef', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getTLDepositLevel', stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'gml', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ name: 'id', type: 'uint32' }, { name: 'referrer', type: 'address' }, { name: 'referralCount', type: 'uint256' }, { name: 'directsIncome', type: 'uint256' }, { name: 'balanceRef', type: 'uint256' }, { name: 'totalDepositedByRefs', type: 'uint256' }, { name: 'numDeposits', type: 'uint32' }, { name: 'totalDepositPercents', type: 'uint256' }, { name: 'totalDepositPrincipals', type: 'uint256' }, { name: 'invested', type: 'uint256' }, { name: 'paidAt', type: 'uint256' }, { name: 'withdrawn', type: 'uint256' }, { name: 'status', type: 'uint8' }], inputs: [{ type: 'address' }], name: 'investors', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'refregistry', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'removeAddressFromBlacklist', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setAmatorDepositLevel', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setBonusRound', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setMaxDeposit', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'min', type: 'uint256' }], name: 'setMinDeposit', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setPartnerDepositLevel', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint8' }], inputs: [{ name: '_wallet', type: 'address' }, { name: 'status', type: 'uint8' }], name: 'setPartnerStatus_0U_1I_2A_3TL_4P_5D_6SA', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'percent', type: 'uint256' }], name: 'setRefBonus', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setTLDepositLevel', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_tariff', type: 'uint256' }, { name: '_status', type: 'bool' }], name: 'setTariffStatus', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'state', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ name: 'time', type: 'uint256' }, { name: 'percent', type: 'uint256' }, { name: 'max_depo', type: 'uint256' }, { name: 'status', type: 'bool' }], inputs: [{ type: 'uint256' }], name: 'tariffs', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalDeposits', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalInvested', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'totalInvestors', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'totalPartners', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalRefRewards', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'totalUsers', stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOff', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOn', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes1', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes2', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'votes3', stateMutability: 'view', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'voting', stateMutability: 'Payable', type: 'Function',
  }, { name: 'withdraw', stateMutability: 'Nonpayable', type: 'Function' }, {
    inputs: [{ name: 'amount', type: 'uint256' }], name: 'withdrawThreeVoices', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_fee', type: 'uint256' }], name: 'xdevfee', stateMutability: 'Payable', type: 'Function',
  }],
};

const MainPoolContract = { contractAddress, contractAddressHex, contractAbi };
export default MainPoolContract;

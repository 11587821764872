/* eslint-disable camelcase */
import { Card, Row } from 'react-bootstrap';
import TronWeb from 'tronweb';

import PropTypes from 'prop-types';
import { fromSun, minimizeWalletLinks } from '../../../../../utils/helpers';
import { TokenImg } from '../../../../../components';

export default function LastTransactions({ lastTransactions = undefined }) {
  const tronWeb = new TronWeb({
    fullHost: 'https://api.trongrid.io',
  });
  // tronWeb.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T');
  const transformedEvents = lastTransactions?.map(
    ({
      result, timestamp, name,
    }, index) => ({
      user: tronWeb?.address?.fromHex(result._user),
      amount: fromSun(result._amount || result.amount),
      dateTime: new Date(timestamp * 1000).toLocaleString(),
      name: name === 'withdrawEvent' ? 'Withdraw' : 'Deposit',
    }),
  )/* .filter(({ amount }) => amount >= 5000) */.slice(0, window.innerWidth < 500 ? 5 : 8);
  return (
    <>
      <Card.Title className="transactions-title">
        Last Transactions
      </Card.Title>
      {lastTransactions && transformedEvents?.map(({
        user, amount, name, dateTime,
      }) => (
        <Row
          key={user + dateTime}
          className="transaction"
          role="row"
        >
          <p>
            {name}
            {' '}
            <span>
              {minimizeWalletLinks(user, 5)}
            </span>
            {' '}
            {Number(amount).toFixed(1)}
            <TokenImg width={15} />
            {/*  TRX */}
          </p>
        </Row>
      ))}
    </>
  );
}

LastTransactions.propTypes = {
  lastTransactions: PropTypes.arrayOf(PropTypes.shape({
    result: PropTypes.shape({ amount: PropTypes.number }),
    block_timestamp: PropTypes.number,
    event_name: PropTypes.string,
  })),
};

/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react';
import { TRX_TARIFFS } from '../constants';
import { MainPoolContract } from '../../contracts';
import { fromSun } from '../helpers';
/**
 * Hook to get data from OLD MAIN SC
 * @param {String} wallet UserWallet
 * @param {Number} numberDeposits n of deposits
 * @param {Number} lastPaymentDate Timestamp of last payment
 * @param {Object} tronWeb TronWeb instance
 * @returns {Object}
 */
export function useGetSmartContractData(wallet, numberDeposits, lastPaymentDate, tronWeb) {
  // console.log({ lastPaymentDate });
  const [balance, setBalance] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [linesData, setLinesData] = useState([]);

  const isMountedRef = useRef(true); // Ref to track if the component is still mounted

  const toDecimal = (value) => Number(value);

  const localData = JSON.parse(localStorage.getItem(`${wallet}_pool`)) || [];
  const fetchData = async (depositIndex, monthlyPercentsArray) => {
    try {
      const contract = await tronWeb.contract(
        MainPoolContract.contractAbi.entrys,
        MainPoolContract.contractAddress,
      );
      // console.log(localData[depositIndex]);

      const depositTariffId = localData[depositIndex]?.depositTariff
        ? localData[depositIndex].depositTariff
        : await contract.methods.getDepositTariff(wallet, depositIndex).call();
      const depositAt = localData[depositIndex]?.depositAt
        ? localData[depositIndex].depositAt
        : await contract.methods.getDepositAt(wallet, depositIndex).call();
      const depositAmount = localData[depositIndex]?.depositAmount
        ? localData[depositIndex].depositAmount
        : await contract.methods.getDepositAmount(wallet, depositIndex).call();

      const formattedDepoAmount = localData[depositIndex]?.depositAmount ? depositAmount
        : fromSun(toDecimal(depositAmount));

      const depositPaidOut = await contract.methods.getDepositPaidOut(wallet, depositIndex).call();
      const currentTariff = TRX_TARIFFS.find((tariff) => tariff.id === depositTariffId);
      const monthly = Math.round(Number((formattedDepoAmount * currentTariff.percents) / 100));
      const nOfMonthToPay = currentTariff.months;
      const monthDiffBetweenLastPaymentAndStartDeposit = Math.floor(
        (lastPaymentDate - depositAt) / (60 * 60 * 24 * 30),
      );
      // console.log({ monthDiffBetweenLastPaymentAndStartDeposit, nOfMonthToPay });
      /* const depositPaidOut = monthDiffBetweenLastPaymentAndStartDeposit > nOfMonthToPay
        ? formattedDepoAmount + (monthly * nOfMonthToPay)
        : monthly * monthDiffBetweenLastPaymentAndStartDeposit; */

      return {
        depositAt: localData[depositIndex]?.depositAt ? depositAt : toDecimal(depositAt),
        depositAmount: formattedDepoAmount,
        // depositPaidOut,
        depositPaidOut: fromSun(toDecimal(depositPaidOut)),
        depositTariff: Number(depositTariffId),
        depositPercentsMonthly: fromSun(toDecimal(monthlyPercentsArray[depositIndex])),
        // depositIsClosed: monthDiffBetweenLastPaymentAndStartDeposit >= nOfMonthToPay,
      };
    } catch (err) {
      console.error(`Error fetching data for deposit ${depositIndex}:`, err);
      return null;
    }
  };

  useEffect(() => {
    isMountedRef.current = true; // Set ref to true when component mounts
    const loadAllData = async () => {
      if (numberDeposits > 0) {
        setLinesData([]);
        setBalance([]);
        setIsFetchingData(true);

        try {
          const contract = await tronWeb.contract(
            MainPoolContract.contractAbi.entrys,
            MainPoolContract.contractAddress,
          );
          const depoPercentsMonthly = await contract.methods.calcDepositPercentsMonthly(wallet)
            .call();
          const results = [];
          for (let index = 0; index < numberDeposits; index += 1) {
            if (!isMountedRef.current) return; // Exit if component is unmounted
            // eslint-disable-next-line no-await-in-loop
            const result = await fetchData(index, depoPercentsMonthly);
            if (!isMountedRef.current) return; // Exit if component is unmounted
            results.push(result);
            // eslint-disable-next-line no-await-in-loop
          }

          if (!isMountedRef.current) return; // Exit if component is unmounted
          // store in localstorage
          localStorage.setItem(`${wallet}_pool`, JSON.stringify(results));
          results.forEach((result) => {
            if (!result) return;

            const {
              depositAt, depositAmount, depositPaidOut,
              depositTariff, depositPercentsMonthly,
            } = result;
            const currentTariff = TRX_TARIFFS.find((tariff) => tariff.id === depositTariff);
            const monthly = Math.round(Number((depositAmount * currentTariff.percents) / 100));
            const depositIsClosed = currentTariff.months * monthly
            + depositAmount === depositPaidOut;
            setLinesData((prev) => {
              if (!isMountedRef.current) return prev; // Prevent state update if unmounted
              return [...prev, {
                tariffId: depositTariff,
                depositAt,
                depositAmount,
                depositPaidOut,
                depositPercentsMonthly,
                depositIsClosed,
              }];
            });

            setBalance((prev) => {
              if (!isMountedRef.current) return prev; // Prevent state update if unmounted
              const checkIfLastPayment = () => Number(depositAmount)
                + Number(depositPercentsMonthly) === Number(depositAmount)
                + Number(depositAmount) * ((currentTariff.months * currentTariff.percents) / 100);

              const checkMonthlyPercents = () => Math.round(
                Number(depositPercentsMonthly - depositPaidOut),
              ) < monthly;

              if (depositIsClosed) {
                return [...prev, 0];
              }
              if (checkIfLastPayment()) {
                return [...prev, Number(depositAmount) + Math.round(Number(
                  depositPercentsMonthly - depositPaidOut,
                ))];
              }

              if (checkMonthlyPercents()) {
                return [...prev, 0];
              }

              return [...prev, Math.round(Number(depositPercentsMonthly - depositPaidOut))];
            });
          });
        } catch (err) {
          console.error('Error fetching data:', err);
        } finally {
          if (isMountedRef.current) setIsFetchingData(false);
        }
      } else {
        setIsFetchingData(false);
      }
    };

    if (wallet && numberDeposits > 0) {
      loadAllData();
    }

    return () => {
      isMountedRef.current = false; // Set ref to false when component unmounts
    };
  }, [wallet, numberDeposits]);
  return {
    balance,
    setBalance,
    isFetchingData,
    linesData,
  };
}

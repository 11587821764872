import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

export default function AppErrorBoundary({ children, resetFunction }) {
  return (
    <ErrorBoundary onReset={resetFunction}>
      {children}
    </ErrorBoundary>
  );
}

AppErrorBoundary.propTypes = {
  resetFunction: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

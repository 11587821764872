import { useEffect, useContext } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import AppContext from '../../appContext';

export default function InfoToast() {
  const { appState, dispatch } = useContext(AppContext);

  const closeToast = () => dispatch({ type: 'setToast', payload: null });

  useEffect(() => {
    const timeout = setTimeout(
      () => { closeToast(); },
      1000 * (appState.toast?.visible || 5),
    );
    return () => clearTimeout(timeout);
  }, [appState.toast]);
  return (
    <ToastContainer
      className="p-3 mb-3"
      // position="middle-center"
      style={{
        zIndex: '1100',
        position: 'absolute',
        top: document.body.getBoundingClientRect().top * -1 + 300,
        right: 10,
      }}
    >
      <Toast
        show={appState.toast !== null}
        onClose={() => closeToast()}
        animation
        bg="dark"
      >
        <Toast.Header>
          <img
            src="/logo_192.png"
            width={20}
            height={20}
            className="rounded me-2"
            alt="trontrade_logo"
          />
          <strong className="me-auto">{appState.toast?.callee}</strong>
        </Toast.Header>
        <Toast.Body as="h5" className="m-0 text-white">{appState.toast?.body || ''}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

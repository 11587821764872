export const initialState = {
  toast: undefined, // { body: 'body', callee: 'system' },
  isLoading: false,
  isMaintenance: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'setToast':
      return { ...state, toast: action.payload };

    case 'setIsLoading':
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
}

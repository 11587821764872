import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../appContext';

export default function SectionTabs() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isActionGoing } = useContext(AppContext);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 500;

  return isMobile ? undefined : (
    <ul
      className="nav nav-tabs nav-justified"
      role="tablist"
    >
      {['investment', 'income', 'structure', 'swap', 'marketing', 'rules', 'calculator'].map((item) => (
        <li className="nav-item" role="presentation" key={item}>
          <button
            type="button"
            role="tab"
            className={pathname.split('?')[0].substring(1) === item ? 'nav-link active' : 'nav-link'}
            onClick={() => { navigate(item); }}
          >
            {t(`layout.tabs.${item}`)}
          </button>
        </li>
      ))}
      {isActionGoing && (
      <li className="nav-item" role="presentation" key="action">
        <button
          type="button"
          role="tab"
          className={pathname.split('?')[0].substring(1) === 'action' ? 'nav-link active' : 'nav-link'}
          onClick={() => { navigate('action'); }}
        >
          {t(`layout.tabs.${'action'}`)}
        </button>
      </li>
      )}
    </ul>
  );
}

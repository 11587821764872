import { useContext } from 'react';
import {
  Row,
} from 'react-bootstrap';
import { CopyBtn, CopyRefLinkBtn } from '../../components';
import LeftCard from './LeftCard/LeftCard';
import RightCard from './RightCard/RightCard';
import { minimizeWalletLinks } from '../../utils/helpers';
import AppContext from '../../appContext';

import './MyWalletStats.css';

export default function MyWalletStats() {
  const {
    userWallet,
  } = useContext(AppContext);
  const walletToDisplay = userWallet;
  const minWallet = minimizeWalletLinks(walletToDisplay, 8);
  const isMobile = window.innerWidth < 450;
  return (
    <div className="my-3">
      {walletToDisplay && (
        <div className="d-flex align-items-center">
          <span className="title-wallet">
            {
              isMobile ? minWallet
                : walletToDisplay
}
          </span>
          <CopyBtn
            txtToCopy={walletToDisplay}
            width={25}
          />
          <CopyRefLinkBtn
            txtToCopy={`${window.location.origin}/investment?ref=${walletToDisplay}`}
            width={17}
            tooltipText="refLink"
          />
        </div>
      )}

      <Row>
        <LeftCard wallet={userWallet} />
        <RightCard />
      </Row>
    </div>
  );
}

import PropTypes from 'prop-types';

function ProgressBar({
  percent, showPercent = true, animated = false, text = undefined,
}) {
  const getColor = () => {
    if (percent <= 33) return '#dc3545'; // Red color for 0-33%
    if (percent <= 66) return '#ffc107'; // Yellow color for 34-66%
    return '#198754'; // Green color for 67-100%
  };

  const progressBarStyle = {
    width: '100%', // Set the width to 100% of its container
    height: '10px', // Decreased height
    backgroundColor: '#e0e0e0', // Background for the empty part of the bar
    borderRadius: '10px', // Adjusted border-radius for new height
    overflow: 'hidden',
    position: 'relative', // Position relative to hold the fill and text
  };

  const fillStyle = {
    width: `${percent}%`,
    height: '100%',
    backgroundColor: getColor(percent),
    transition: 'width 0.5s ease-in-out', // Smooth transition for width change
    position: 'absolute', // Ensure it starts from the left
    top: 0, // Align to the top
    left: 0, // Align to the left
    backgroundImage: animated
      ? `repeating-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.3) 0px,
          rgba(255, 255, 255, 0.3) 10px,
          transparent 10px,
          transparent 20px
        )`
      : 'none', // Striped background when animated
    backgroundSize: '20px 20px',
    animation: animated ? 'stripeAnimation 1s linear infinite' : 'none', // Animation if animated prop is true
  };

  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: percent > 50 ? '#ffffff' : '#000000', // Black or white text based on fill
    fontWeight: 'bold',
    fontSize: '10px', // Adjust font size if needed
    zIndex: 1, // Ensure text is above the fill
    display: showPercent || text ? 'block' : 'none', // Show if showPercent or text is provided
  };

  return (
    <div style={progressBarStyle} role="progressbar">
      <div style={fillStyle} />
      <div style={textStyle}>{text || `${percent}%`}</div>
    </div>
  );
}

// Add the keyframes for the stripe animation
const stripeAnimationStyle = `
@keyframes stripeAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}
`;

// Inject the keyframes style into the document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = stripeAnimationStyle;
document.head.appendChild(styleSheet);

export default ProgressBar;
ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  showPercent: PropTypes.bool,
  animated: PropTypes.bool,
  text: PropTypes.string,
};

import { useEffect, useState, useCallback } from 'react';

export function useGetLastContractEvents(contractAddress, tronWeb) {
  const [lastEvents, setLastEvents] = useState();
  const [error, setError] = useState(null);

  // Memoize fetchData function to prevent unnecessary re-creations
  const fetchData = useCallback(async () => {
    try {
      const events = await tronWeb.getEventResult(contractAddress, {
        size: 10,
        onlyConfirmed: true,
      });
      setLastEvents(events);
      setError(null);
    } catch (fetchingError) {
      setError(fetchingError);
    }
  }, [tronWeb, contractAddress]);

  useEffect(() => {
    fetchData(); // Fetch data initially
    const intervalId = setInterval(fetchData, 1000 * 60 * 5); // Refetch every 5 minutes
    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [fetchData]);

  const refetchDataOnError = useCallback(() => {
    if (error) {
      fetchData();
    }
  }, [error, fetchData]);

  return { lastEvents, error, refetchDataOnError };
}

import { Col } from 'react-bootstrap';
import {
  GB, DE, UA, RU, IN,
} from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const changeLng = (language) => i18n.changeLanguage(language);

  return (
    <Col
      xs={12}
      sm={9}
      md={8}
      lg={7}
      xl={7}
      xxl={8}
      data-testid="language-switcher"
    >
      <strong>{`${t('layout.header.langSwitchLabel')} : `}</strong>
      <GB className="lng-flag mx-2" data-testid="GB" onClick={() => changeLng('en')} />
      <UA className="lng-flag mx-2" data-testid="UA" onClick={() => changeLng('ua')} />
      <DE className="lng-flag mx-2" data-testid="DE" onClick={() => changeLng('de')} />
      <IN className="lng-flag mx-2" data-testid="IN" onClick={() => changeLng('hi')} />
      <RU className="lng-flag mx-2" data-testid="RU" onClick={() => changeLng('ru')} />
    </Col>
  );
}

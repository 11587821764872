import {
  lazy, useReducer,
} from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import TronWeb from 'tronweb';
import { initialState, reducer } from './store/reducer';
import { AppStateProvider } from './appContext';
import {
  Header, Footer,
} from './layout';
import AppRouter from './Routers/Router';
import { InfoToast } from './components';
import {
  useGetUsersWallet,
  useGetWalletValues,
} from './utils/hooks';
import { TRX_TARIFFS } from './utils/constants';

import './App.css';

const Maintenance = lazy(() => import('./layout/Maintenance'));
// only for OLD smart contracts: main and Bonus
const tronWebQuickNode = new TronWeb({
  fullHost: 'https://chaotic-tiniest-theorem.tron-mainnet.quiknode.pro/2c076d7b56ac3882161d4c1270f49ecbf7e53d6f',
});
tronWebQuickNode.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T');

/* const tronWebGetBlock = new TronWeb({
  fullHost: 'https://go.getblock.io/ce300265336e47c2b1d3d37074720eb4',
});
tronWebGetBlock.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T'); */

const tronWebTronGrid = new TronWeb({
  fullHost: 'https://api.trongrid.io',
});
tronWebTronGrid.setAddress('TB15nHEnAP2ZyyvPZuyadJq5G7u9H3839T');

export default function App() {
  const { userWallet } = useGetUsersWallet();
  const {
    currentInvestorObject, userTokensBalance,
    balanceAndEnergy,
  } = useGetWalletValues(/* tronWeb. */tronWebTronGrid, userWallet);
  const [appState, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line global-require
  window.Buffer = window.Buffer || require('buffer').Buffer;

  return (
    <AppStateProvider value={{
      appState,
      dispatch,
      contractTariffs: TRX_TARIFFS,
      userWallet,
      currentInvestorObject,
      userTokensBalance,
      balanceAndEnergy,
      tronWeb: { /* tronWebGetBlock, */ tronWebQuickNode, tronWebTronGrid },
      isActionGoing: (Date.now() >= new Date('2024-08-24T16:00:00Z').getTime() && Date.now() <= new Date('2024-09-30T22:00:00Z').getTime()) && false,
      isMaintenance: appState.isMaintenance,
    }}
    >
      <HelmetProvider>
        <Helmet titleTemplate="TronTrade - %s" />
        <BrowserRouter>
          <div className="body-container">
            <Header />
            <Container>
              <main style={{ minHeight: '85vh' }}>
                {appState.isMaintenance ? <Maintenance /> : <AppRouter />}
              </main>
            </Container>
          </div>
          <Footer />
        </BrowserRouter>
        {appState.toast && <InfoToast />}
      </HelmetProvider>
    </AppStateProvider>
  );
}

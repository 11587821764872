/* eslint-disable no-unsafe-optional-chaining */
import {
  Card, Col, Row, OverlayTrigger, Tooltip, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useCallback } from 'react';
import { minimizeWalletLinks } from '../../../utils/helpers';
import { CopyBtn, TokenImg } from '../../../components';
import { useGetExchangeRates } from '../../../utils/hooks';
import { Action, StatusRow } from './components';
import AppContext from '../../../appContext';

export default function LeftCard() {
  const [tokenToShowValues, setTokenToShowValues] = useState(0);
  const { t } = useTranslation();
  const { isActionGoing, currentInvestorObject, userWallet } = useContext(AppContext);
  const {
    referrer, invested, withdrawn, totalDepositedByRefs, referralCount,
  } = currentInvestorObject || {};
  /* const {
    invested, referralCount,
    referrer, status, totalDepositedByRefs, withdrawn,
  } = currentInvestorObject; */

  const exchangeRate = useGetExchangeRates();
  // const exchangeRate = { lastPrice: 10 };

  const renderTooltip = useCallback((props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.hint}
    </Tooltip>
  ), []);

  const minimizedRefWallet = referrer === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
    ? 'none' : minimizeWalletLinks(referrer, 3);
  const refWallet = referrer === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
    ? 'none' : referrer;

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={7}
      xl={7}
      xxl={8}
    >
      <Card
        className="my-3 p-3 overview-container"
      >
        <Card.Title className="overview-title">
          {t('myWalletStats.overview.overview')}
        </Card.Title>
        <Row className="overview-row" style={{ display: 'flex', alignItems: 'flex-start' }}>
          {' '}
          {/* Adjust alignment */}
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.status') })}
          >
            <p className="green-question" style={{ marginTop: '4px' }}>
              {' '}
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption" style={{ marginTop: '4px' }}>
            {' '}
            {t('myWalletStats.overview.status')}
          </Col>
          <Col className="status-container" style={{ marginTop: '-3px' }}>
            <StatusRow />
          </Col>
        </Row>

        <Row
          style={{
            borderTop: '1px solid #f0f2f3',
          }}
          className="overview-row"
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.invested') })}
          >
            <p className="green-question">
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption">{t('myWalletStats.overview.invested')}</Col>
          <Col className="overview-val">
            {invested ? (
              <>
                {/* exchangeRate &&  */tokenToShowValues === 0 ? invested
                  : Number(invested * exchangeRate?.lastPrice).toFixed(2)}
                <TokenImg width={15} token={tokenToShowValues} />
              </>
            ) : '-'}
          </Col>
        </Row>
        <Row className="overview-row">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.withdrawn') })}
          >
            <p className="green-question">
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption">{t('myWalletStats.overview.withdrawn')}</Col>
          <Col className="overview-val">
            {withdrawn ? (
              <>
                {/* exchangeRate &&  */tokenToShowValues === 0 ? withdrawn
                  : Number(withdrawn * exchangeRate?.lastPrice).toFixed(2)}
                <TokenImg width={15} token={tokenToShowValues} />
              </>
            ) : '-'}
          </Col>
        </Row>
        <Row
          className="overview-row"
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.referrer') })}
          >
            <p className="green-question">
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption">{t('myWalletStats.overview.referrer')}</Col>
          <Col className="ref-wallet">
            {window.innerWidth < 450 ? minimizedRefWallet : refWallet}
            {(refWallet !== 'none' && typeof refWallet !== 'undefined') ? (
              <CopyBtn
                txtToCopy={window?.tronWeb?.address.fromHex(referrer)}
                width={25}
              />
            ) : '-'}
          </Col>
        </Row>

        <Row
          className="overview-row"
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.refCount') })}
          >
            <p className="green-question">
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption">{t('myWalletStats.overview.refCount')}</Col>
          <Col className="overview-val">{userWallet === 'TTbVPbrgH5WbrvWVCRsF3Ljuvt1UVs9CnC' ? referralCount - 1 : referralCount || '-'}</Col>
        </Row>
        <Row
          className="overview-row"
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ hint: t('myWalletStats.overview.hints.depByRefs') })}
          >
            <p className="green-question">
              ?
            </p>
          </OverlayTrigger>
          <Col className="caption">{t('myWalletStats.overview.depByRefs')}</Col>
          <Col className="overview-val">
            {totalDepositedByRefs ? (
              <>
                {/* exchangeRate &&  */tokenToShowValues === 0
                  ? totalDepositedByRefs
                  : Number(totalDepositedByRefs
                  * exchangeRate?.lastPrice).toFixed(2)}
                <TokenImg width={15} token={tokenToShowValues} />
              </>
            ) : '-'}
          </Col>
        </Row>

        {isActionGoing && (
        <Action
          wallet={userWallet}
        />
        )}
        <Row>
          <Form className="my-1 mx-1">
            <Form.Check
              type="checkbox"
              id="custom-switch"
              label={t('calculator.labels.currencySwitch')}
              onChange={(event) => setTokenToShowValues(Number(event.target.checked))}
            />
          </Form>
        </Row>
      </Card>
    </Col>
  );
}

LeftCard.propTypes = {
  wallet: PropTypes.string,
};

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { ADMIN_WALLETS } from '../../../utils/constants';

export default function DesktopFooter({ userWallet = undefined }) {
  const isAdmin = ADMIN_WALLETS.some(
    ({ wallet }) => wallet === userWallet,
  );
  return (
    <Row className="gap-2">
      <LanguageSwitcher />
      <Col>
        {
  isAdmin
  && <Link to="/admin">Admin</Link>
}
      </Col>
    </Row>
  );
}

DesktopFooter.propTypes = {
  userWallet: PropTypes.string,
};

import { useState } from 'react';
import { Row, Offcanvas, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiMiniWallet, HiOutlineWallet } from 'react-icons/hi2';
import { IoWalletOutline, IoWallet } from 'react-icons/io5';
// eslint-disable-next-line no-unused-vars
import { RiExchangeBoxLine, RiExchangeBoxFill } from 'react-icons/ri';
import { PiTreeStructure, PiTreeStructureFill } from 'react-icons/pi';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { SlOptions } from 'react-icons/sl';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { ADMIN_WALLETS } from '../../../utils/constants';

export default function MobileFooter({ userWallet = undefined }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isAdmin = ADMIN_WALLETS.some(
    ({ wallet }) => wallet === userWallet,
  );
  const activeLink = pathname.split('?')[0].substring(1);
  return (
    <Row className="d-flex flex-row justify-content-center">
      <button
        type="button"
        style={{ fontSize: '14px' }}
        onClick={() => { navigate('investment'); }}
        className={activeLink === 'investment' ? 'nav-button active-button' : 'nav-button'}
      >
        {activeLink === 'investment' ? <HiMiniWallet size={25} /> : <HiOutlineWallet size={25} />}
        {t('layout.tabs.investment')}
      </button>
      <button
        type="button"
        style={{ fontSize: '14px' }}
        onClick={() => { navigate('income'); }}
        className={activeLink === 'income' ? 'nav-button active-button' : 'nav-button'}
      >
        {activeLink === 'income' ? <IoWallet size={25} /> : <IoWalletOutline size={25} />}
        {t('layout.tabs.income')}
      </button>
      <button
        type="button"
        style={{ fontSize: '14px' }}
        onClick={() => { navigate('structure'); }}
        className={activeLink === 'structure' ? 'nav-button active-button' : 'nav-button'}
      >
        {activeLink === 'structure' ? <PiTreeStructureFill size={25} /> : <PiTreeStructure size={25} />}
        {t('layout.tabs.structure')}
      </button>
      <button
        type="button"
        style={{ fontSize: '14px' }}
        onClick={() => { navigate('swap'); }}
        className={activeLink === 'swap' ? 'nav-button active-button' : 'nav-button'}
      >
        {activeLink === 'swap' ? <CgArrowsExchangeAlt size={25} /> : <CgArrowsExchangeAlt size={25} />}
        Swap
      </button>
      <button
        type="button"
        style={{ fontSize: '14px' }}
        onClick={handleShow}
        className="nav-button"
      >
        <SlOptions size={25} />
        {t('layout.tabs.options')}
      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('layout.tabs.options')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column my-2">
            {['marketing', 'rules', 'calculator', 'action'].map((item) => (
              <div key={item}>
                <Nav.Link href={item}>{t(`layout.tabs.${item}`)}</Nav.Link>
                <hr className="my-1" />
              </div>
            ))}
            {
isAdmin
&& (
<>
  <Nav.Link href="admin">Admin</Nav.Link>
  <hr className="my-1" />
</>
)
}
          </Nav>
          <LanguageSwitcher />
        </Offcanvas.Body>
      </Offcanvas>
    </Row>
  );
}

MobileFooter.propTypes = {
  userWallet: PropTypes.string,
};

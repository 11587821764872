export const AVAILABLE_TOKENS = ['trx', 'usdt', 'tex'];
export const ADMIN_WALLETS = [
  { wallet: 'TLM3KGPh8NYSoQKAwVLekkSvVvTBp2xQw3' },
  { wallet: 'TLA5YwGqRgJJBVgcpPJFe7fe35BVLdZSDV' },
  { wallet: 'TRSnwp31gmFCpACn84EfdrtQorKToYaH6V' },
  { wallet: 'TCoXAX3tUdUXRzqFbwYHiK6YVnwNWwnz34' },
  { wallet: 'TWtdWzTk8AFMs1Msi1cJC93heBZmqFDdN2' },
];
export const FEE_LIMIT_FOR_TRANSACTION = 3e8;

/**
 * Available tariffs
 * id: used to send to smart contract
 * days/months and percents: are just for information
 */
export const TRX_TARIFFS = [
  {
    id: 0, months: 3, percents: 4, sleeping: false, isSpecial: false,
  },
  {
    id: 1, months: 6, percents: 6, sleeping: false, isSpecial: false,
  },
  {
    id: 2, months: 12, percents: 8, sleeping: false, isSpecial: false,
  },
  {
    id: 3, months: 18, percents: 10, sleeping: false, isSpecial: false,
  },
  {
    id: 4, months: 3, percents: 5, sleeping: true, isSpecial: false,
  },
  {
    id: 5, months: 6, percents: 8, sleeping: true, isSpecial: false,
  },
  {
    id: 6, months: 12, percents: 10, sleeping: true, isSpecial: false,
  },
  {
    id: 7, months: 18, percents: 12, sleeping: true, isSpecial: false,
  },
  {
    id: 8, months: 12, percents: 6, sleeping: true, isSpecial: false,
  },
  {
    id: 9, months: 18, percents: 8, sleeping: true, isSpecial: false,
  },
  {
    id: 10, months: 12, percents: 12, sleeping: true, isSpecial: false,
  },
  {
    id: 11, months: 18, percents: 14, sleeping: true, isSpecial: false,
  },
  {
    id: 12, months: 24, percents: 12, sleeping: false, isSpecial: true,
  },
  {
    id: 13, months: 24, percents: 12, sleeping: true, isSpecial: true,
  }];

export const BONUS_CONTRACT_TRX_TARIFFS = [
  {
    id: 0, months: 3, percents: 4, sleeping: false, frozen: false,
  },
  {
    id: 1, months: 6, percents: 6, sleeping: false, frozen: false,
  },
  {
    id: 2, months: 12, percents: 8, sleeping: false, frozen: false,
  },
  {
    id: 3, months: 18, percents: 10, sleeping: false, frozen: false,
  },
  {
    id: 4, months: 3, percents: 5, sleeping: true, frozen: false,
  },
  {
    id: 5, months: 6, percents: 8, sleeping: true, frozen: false,
  },
  {
    id: 6, months: 12, percents: 10, sleeping: true, frozen: false,
  },
  {
    id: 7, months: 18, percents: 12, sleeping: true, frozen: false,
  },
  {
    id: 8, months: 12, percents: 6, sleeping: true, frozen: false,
  },
  {
    id: 9, months: 18, percents: 8, sleeping: true, frozen: false,
  },
  {
    id: 10, months: 12, percents: 12, sleeping: true, frozen: false,
  },
  {
    id: 11, months: 18, percents: 14, sleeping: true, frozen: false,
  }];

/**
 * List of possible statuses of the user
 */
export const USER_STATUSES = [
  {
    id: 0, color: 'black', title: 'User', selfCapital: 50000, refNeededCapital: 150000,
  },
  {
    id: 1, color: 'black', title: 'Investor', selfCapital: 50000, refNeededCapital: 150000,
  },
  {
    id: 2, color: '#92b5f9', title: 'Amateur', selfCapital: 150000, refNeededCapital: 1500000,
  },
  {
    id: 3, color: 'violet', title: 'Partner', selfCapital: 350000, refNeededCapital: 3500000,
  },
  {
    id: 4, color: 'green', title: 'Director', selfCapital: 350000, refNeededCapital: 15000000,
  },
  {
    id: 5, color: 'orange', title: 'Ambassador', refNeededCapital: 15000000,
  },
  {
    id: 6, color: '#8d9299', title: 'Silver Ambassador', refNeededCapital: 30000000,
  },
  {
    id: 7, color: '#cfc291', title: 'Gold Ambassador', refNeededCapital: 60000000,
  },
  {
    id: 8, color: '#5a5a5e', title: 'Platin Ambassador', refNeededCapital: 100000000,
  }];

export const USER_STATUSES_LINE_PERCENTS = [{
  1: 0, 2: 0, 3: 0, 4: 0,
}, {
  1: 0, 2: 0, 3: 0, 4: 0,
}, {
  1: 3, 2: 0, 3: 0, 4: 0,
}, {
  1: 7, 2: 3, 3: 0, 4: 0,
}, {
  1: 10, 2: 3, 3: 2, 4: 0,
}, {
  1: 10, 2: 3, 3: 2, 4: 1,
}, {
  1: 10, 2: 3, 3: 2, 4: 1,
}, {
  1: 10, 2: 3, 3: 2, 4: 1,
}, {
  1: 10, 2: 3, 3: 2, 4: 1,
}];

export const UI_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Українська', value: 'ua' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Русский', value: 'ru' },
  { label: 'हिंदी', value: 'hi' },
];

export const ACTION_PRIZES = [
  {
    title: 'AirPods Pro', price: 50000000000, percent: [0, 49.5], link: 'https://www.apple.com/airpods/',
  },
  {
    title: 'Apple Watch 9', price: 250000000000, percent: [50, 99.5], link: 'https://www.apple.com/apple-watch-series-9/',
  },
  {
    title: 'Ipad Air 256GB', price: 500000000000, percent: [99.5, 100], link: 'https://www.apple.com/ipad-air/',
  }, /* ,
  {
    title: "MacBook Air 13'", price: 600000000000, percent: [75, 99], link: 'https://www.apple.com/macbook-air-13-and-15-m2/',
  },
  {
    title: 'Iphone 15 Pro Max', price: 800000000000, percent: [100, 1000], link: 'https://www.apple.com/iphone-15-pro/',
  }, */
];
